import { createContext } from './utils/create-context';

type ThemeMode = 'light' | 'dark';

export const [ThemeModeProvider, useThemeMode] =
  createContext<ThemeMode>('ThemeModeProvider');

export const [ThemeSwitchProvider, useThemeSwitch] = createContext<
  (theme: ThemeMode) => void
>('ThemeSwitchProvider');
